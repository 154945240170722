.section {
    padding: 3rem 3rem;
}

.sectionHeader {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.contentContainer {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.handIcon {
    width: 3rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.eficienciaText {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    gap: 1rem;
    padding-left: 5rem;
}

.eficienciaText p {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
}

.eficienciaText p span {
    font-weight: bold;
}

.graphContainer {
    position: relative;
    width: 100%;
    max-width: 62rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    margin-top: 3rem;
    border-radius: 0rem 0rem 0rem 1.875rem;
}

.graphTitle {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.graphPj {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 25rem;
    height: 3.5rem;
    flex-shrink: 0;
    border-radius: 0rem 0rem 0rem 1.875rem;
    background: #DCE6FF;
    position: relative;
    overflow: hidden;
}

.graphPf {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 25rem;
    height: 3.5rem;
    flex-shrink: 0;
    border-radius: 0rem 0rem 0rem 1.875rem;
    background: #D9D9D9;
    position: relative;
    overflow: hidden;
}

.barsContainer {
    position: relative;
    width: 100%;
    border-radius: 0rem 0rem 0rem 1.875rem;
    height: 100%;
}

.bar {
    position: absolute;
    bottom: 0;
    height: 100%;
    border-radius: 0rem 0rem 0rem 1.875rem;
}

.lightBlue {
    background: #5B8BF7;
    border-radius: 0;
}

.darkBlue {
    background: #0019FF;
    border-radius: 0;
}

.darkGray {
    background: #505050;
    border-radius: 0;
}

.porcentagemPj {
    width: 100%;
    margin-right: 1.2rem;
    color: #1B29F0;
    text-align: right;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120.8%;
}

.porcentagemPf {
    width: 100%;
    margin-right: 1.2rem;
    color: #505050;
    text-align: right;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120.8%;
}

.graphSection {
    align-self: center;
}

@media screen and (max-width: 987px) {
    .section {
        padding: 1rem 3rem;
    }

    .sectionHeader {
        flex-flow: column;
        margin-top: 3rem;
    }

    .handIcon {
        width: 4rem;
    }

    .title {
        font-size: 1.6rem;
    }

    .boxGestaoPlin {
        width: 17rem;
    }

    .eficienciaText {
        width: 80%;
        padding-left: 6rem;
    }

    .eficienciaText p {
        font-size: 1.2rem;
    }

    .graphSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 82%;
        padding-left: 2rem;
        margin-top: -2rem;
    }

    .graphContainer {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
    }

    .graphPj,
    .graphPf {
        width: 100%;
        max-width: 100%;
        height: 3.5rem;
        border-radius: 0rem 0rem 0rem 1.875rem;
        display: flex;
        align-items: center;
    }

    .barsContainer {
        position: relative;
        width: 100%;
        border-radius: 0;
        height: 100%;
        display: flex;
    }

    .bar {
        position: relative;
        height: 100%;
        border-radius: 0;
    }

    .titleSection {
        padding-left: 0rem;
    }

}

@media screen and (max-width: 767px) {
    .section {
        padding: 1rem 1rem;
    }

    .handIcon {
        width: 3rem;
    }

    .eficienciaText {
        padding-left: 1rem;
        margin-left: 0.5rem;
    }

    .graphSection {
        width: 90%;
        padding-left: 0rem;
    }

}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .section {
        padding: 0rem 3rem;
        margin-top: 2rem;
        margin-bottom: 22rem;
    }

    .title {
        font-size: 1.8rem;
    }

    .titleSection {
        gap: 1.2rem;
        margin-left: -0.8rem;
    }

    .handIcon {
        width: 3rem;
        margin-bottom: 16px;
    }

    .eficienciaText {
        padding-left: 3.5rem;
        gap: 0.8rem;

    }

    .eficienciaText p {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    .porcentagemPj {
        font-size: 1.3rem;
    }

    .porcentagemPf {
        font-size: 1.3rem;
    }

    .graphTitle {
        font-size: 1.3rem;
    }

    .graphContainer {
        margin-top: 1rem;
    }

    .graphPj {
        width: 23rem;
        height: 2.3rem;
        border-radius: 0rem 0rem 0rem 1rem;
    }

    .graphPf {
        width: 23rem;
        height: 2.3rem;
        border-radius: 0rem 0rem 0rem 1rem;
    }

}