.header {
    display: none !important;
    background-color: var(--azul-1);
    padding: 1rem 3rem 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plinLogo {
    width: 5rem;
}

.infoContainer {
    display: flex;
}

.leftText {
    margin-right: 1rem;
}

.leftText h2 {
    color: #FFF;
    text-align: right;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.rightText {
    margin-right: 1rem;
    white-space: nowrap;
}

.righText h2 {
    color: #FFF;
    text-align: right;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media print {
	.header {
        display: flex !important;
        margin-top: 15rem;
    }

    .leftText h2 {
        font-size: 1.3rem;
    }

    .righText h2 {
        font-size: 1.3rem !important ;
    }
}
