.section {
    padding: 3rem 3rem;
    margin-top: 3rem;
}

.sectionHeader {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.lampIcon {
    width: 3rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.boxInfos {
    display: flex;
    height: 13rem;
    margin-right: 50px;
}

.boxInfosContainer {
    display: flex;
    gap: 4rem;
}

.boxInfosTitulos {
    margin-right: 1rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}

.boxInfosValores {

    height: 13rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}

.boxInfosTitulos h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.boxInfosValores h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.resumoProposta {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: .5rem;
    gap: 1rem;
    padding-left: 5rem;
}

@media screen and (max-width: 767px) {
    .boxInfos {
        height: auto;
        margin: 0;
    }

    .boxInfosValores {
        height: 0;
    }

    .boxInfosValores h2 {
        font-size: 1.1rem;
    }

    .boxInfosTitulos h2 {
        font-size: 1.1rem;
    }

    .boxInfosTitulos {
        white-space: nowrap;
        ;
    }

    .section {
        padding: 1rem 1rem;
        margin-top: 6rem;
    }

    .lampIcon {
        width: 2rem;
    }

    .title {
        font-size: 1.6rem;
    }

    .titleSection {
        gap: 1rem;
    }

    .resumoProposta {
        padding: 0;
    }

    .boxInfosContainer {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.6rem;
    }
}