.section {
    padding: 3rem 3rem;
}

.sectionHeader {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.simboloPlin {
    width: 3rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.boxInfosTitulos {
    margin-right: 1rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}

.boxInfosValores {
    height: 13rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}

.boxInfosTitulos h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.boxInfosValores h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.resumoPropostaDesktop {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: .5rem;
    gap: 1rem;
    padding-left: 5rem;
}

.resumoPropostaMobile {
    display: none;
}


.divisionLine {
    display: none !important;
    height: .15rem;
    background-color: #E7EFFE;
}

@media screen and (min-width: 768px) and (max-width: 987px) {
    .boxInfosTitulos h2 {
        font-size: 1rem;
    }

    .boxInfosValores h2 {
        font-size: 1rem;
    }

}

@media screen and (max-width: 767px) {
    .section {
        padding: 1rem 1rem;
    }

    .resumoPropostaDesktop {
        display: none;
    }

    .resumoPropostaMobile {
        display: block;
    }

    .title {
        font-size: 1.6rem;
    }

    .titleSection {
        margin-bottom: 2rem;
        gap: 1rem;
    }

}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .section {
        margin-bottom: -2rem;
    }

    .title {
        font-size: 1.8rem;
    }

    .titleSection {
        gap: 2.3rem;
    }

    .simboloPlin {
        width: 2.6rem;
    }

    .resumoProposta {
        margin-left: -1rem;
    }

    .boxInfosValores h2 {
        font-size: 1.3rem;
    }

    .boxInfosTitulos h2 {
        font-size: 1.3rem;
    }

    .divisionLine {
        display: block !important;
        height: .15rem;
        background-color: #E7EFFE;
    }
}