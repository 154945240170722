.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  text-decoration: none;
}

.buttonContainer:hover .gradientButton {
  background: var(--azul-1);
}

.buttonContainer:hover img {
  filter: brightness(0) invert(1);
} 

.gradientButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 10px;
  background: linear-gradient(180deg, #FFF 0%, #E5E7FA 100%);
  transition: all ease-in 0.1s;
}

.iconImage {
  width: 2rem;
  height: auto;
  object-fit: contain;
  transition: all ease-in 0.1s;
}

.textButton {
  display: block;
  text-align: center;
  color: #0019FF;
  font-family: Epilogue;
  font-size: 0.87925rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all ease-in 0.4s;
}

