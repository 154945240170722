table {
	border-spacing: 0;
	border: none;
	width: 100%;
}

tr {
	padding: 0 0.7rem;
	text-align: center;

}

thead th {
	padding: 0.875rem 1.05rem;
	font-weight: 600;
	color: #FFF;
	font-family: Epilogue;
	font-size: 1rem !important;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	color: #FFF;
	background: var(--degrade, #2C3AFC);
	border: none;
}


th,
td {
	font-size: 0.7rem !important;
	vertical-align: middle;
	white-space: nowrap;
}

th {
	padding: 1.2rem 0.7rem !important;
	font-weight: 600;
}

td {
	padding: 1.8rem 1.2rem !important;
	justify-content: center;

}

img {
	width: 1.2rem;
}

.isLoading {
	animation: pulse 0.5s alternate infinite;
}

.paginationSection {
	display: flex;
	justify-content: flex-end;
}

.total {
	display: flex;
	align-items: center;
	gap: 0.4rem;
	padding: 1.4rem 1.2rem 0rem 1.2rem;
}

.total img {
	width: 1rem;
}

.total p {
	font-size: 0.6rem;
	color: #575757;
}



.tablePaginationActions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.7rem;
	margin-top: 1.4rem;
	margin-right: 1rem;
}

.tablePaginationActions button {
	color: var(--preto);
	background-color: var(--branco);

	outline: none;
	border: 1px solid var(--cinza-claro);
	border-radius: 0.218rem;
}

.tablePaginationActions button:hover {
	cursor: pointer;
}

.tablePaginationActions .pages {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;

	margin: 0 0.7rem;
	gap: 0.7rem;
}

.tablePaginationActions .page {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 1.53rem;
	height: 1.53rem;

	font-size: 0.612rem;
	color: var(--azul-1);
	background-color: var(--branco);

	border: 1px solid var(--cinza-claro);
	border-radius: 0.218rem;
}

.tablePaginationActions .currentPage {
	color: var(--branco);
	background-color: var(--azul-1);
}

.tablePaginationActions .page:hover {
	cursor: pointer;
}

.tablePaginationActions .disabled {
	color: var(--cinza-disabled);
	background-color: var(--branco);
}

.tablePaginationActions .disabled img {
	filter: invert(1);
}

.tablePaginationActions .disabled:hover {
	cursor: not-allowed;
}


@media print {
	thead th {
		font-size: 1.2rem !important;
	}
}



@keyframes pulse {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}