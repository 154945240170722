.container {
    border: 1.071px solid #0019FF;
    border-radius: 2.04575rem;
    display: inline-flex;
    justify-content: center;
    flex-shrink: 0;
    padding: .5rem;
}

.textBox {
    color: #1B29F0;
    text-align: center;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120.8%;
}


@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .textBox {
        font-size: 1rem;
    }

}


@media screen and (max-width: 767px) {
    .container {
        padding: 0.5rem 1rem 0.5rem 1rem;
    }

    .textBox{
        font-size: .8rem;
    }

}