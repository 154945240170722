@media screen and (max-width: 767px) {
	.container {
		overflow-x: hidden;
	}
}

@page {
	margin: 0;
	-webkit-print-color-adjust: exact !important;
	color-adjust: exact !important;
	print-color-adjust: exact !important;
	size: A4;
}

@media print {
	body {
		-webkit-print-color-adjust: exact;
	}

	.container {
		overflow-x: visible;
	}
}