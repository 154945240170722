@page {
    margin: 0;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
    size: A4;
    font-family: Epilogue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.inputContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
}

.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160vh;
    height: 100vh;
}

.formProposta {
    display: flex;
}

.imageContainer {
    display: flex;
    height: auto;
    width: 30%;
}

.imageContainer div {
    position: relative;
    background: no-repeat;
    background-image: url(../../assets/imageForm.png);
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.headerForm {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.headerForm p {
    font-size: 1.6rem;
}

.simboloPlin {
    height: 5vh;
    width: 5vh;
}

.inputForm {
    display: flex;
    justify-content: space-between;
    width: 24rem;
    flex-direction: column;
    row-gap: 24px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selectEstado {
    width: 80%;
}

.inputsRegiao {
    display: flex;
    gap: 2rem;
}

@media screen and (min-width: 767px) and (max-width: 968px) {
    .inputContainer {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .headerForm {
        gap: 1rem;
    }

    .headerForm p {
        font-size: 1.5vh;
    }
}

@media screen and (max-width: 767px) {
    .inputContainer {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        justify-items: center;
    }

    .headerForm {
        gap: 1rem;
    }

    .headerForm p {
        font-size: 2vh;
    }

    .imageContainer {
        display: none;
    }

    .formContainer {
        padding: 2rem;
    }

    .inputForm {
        width: 100%;
    }

    .inputsRegiao {
        flex-direction: column;
    }
}