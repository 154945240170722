.container {
    display: inline-flex;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-left: 4rem;
    margin-bottom: 1rem;
    width: auto;
    max-width: 20rem;
    padding: 2rem;
    border-radius: 0rem 0rem 0rem 2.70375rem;
    background: linear-gradient(146deg, #F9F9FF 0%, #FFF 0.01%, #F3F4FF 97.92%);
}

.textNumbers {
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    margin-left: 1rem;
    font-weight: 600;
    line-height: 120.8%;
}

.textDesc {
    color: #9C9C9C;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    margin-left: 1rem;
    font-weight: 500;
    line-height: 120.8%;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        margin: 0;
    }

    .container {
        width: 15.5rem;
        padding: 2rem 0rem 2rem 0rem;
    }

    .textNumbers {
        font-size: 1.5rem;
    }

    .textDesc {
        font-size: 1rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 987px) {
    .container {
        margin-left: 0rem;
        margin-bottom: 1rem;
        max-width: 14rem;
    }

    .textNumbers {
        font-size: 1.4rem;
    }
}


@media screen and (max-width: 767px) {
    .container {
        margin-left: -2rem;
        padding: 1rem;
        max-width: 8rem;
    }

    .textNumbers {
        font-size: 1.1rem;
    }

    .textDesc {
        font-size: .8rem;
    }
}