.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0rem 2rem;
}

.loginForm {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 20rem;
    gap: 3rem;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loginForm h1 {
    font-size: 1.8rem;
    font-weight: 500;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.loginButton:hover {
    background-color: #f5e100;
    color: #1b29f0;
}

.headerForm {
   display: flex;
   flex-direction: column;
   gap: 2rem;
}

.simboloPlin {
    width: 2.5rem;
}