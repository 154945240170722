.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-left: 3rem;
    margin-bottom: 2rem;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.tableIcon {
    width: 3rem;
    margin-bottom: 3rem;
}

.container h2 {
    color: #808080;
    font-family: Epilogue;
    margin-top: 1rem;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120.8%;
}

.fluxoTableAndImage {
    position: relative;
    padding: 0rem 8rem 0 8rem;
}

.imgLinha {
    display: none !important;
}

@media screen and (max-width: 767px) {
    .fluxoTableAndImage {
        padding: 0rem 1rem 0rem 1rem;
    }

    .titleSection {
        padding: 0;
    }

    .title {
        font-size: 1.6rem;
    }

    .container h2 {
        font-size: 1.2rem;
    }
}

@media print {
    .analiseSection {
        margin-top: 5rem;
        margin-bottom: 31rem;
        padding: 1rem 1rem;
    }

    .container {
        margin-bottom: 2rem;
    }

    .container h2 {
        font-size: 1.2rem;
    }

    .tableIcon {
        margin-bottom: 5rem;
    }

    .imgLinha {
        display: flex !important;
        position: absolute;
        bottom: -20rem;
        width: 40rem;
        right: -2rem;
        z-index: -1;
    }

    .titleSection {
        margin-bottom: 1rem;
        margin-left: -1rem;
    }

    .title {
        font-size: 1.8rem;
    }

    .analiseSection {
        margin-top: 2rem;
        margin-left: 0rem;
    }

    .container {
        margin-left: -1rem;
    }

    .fluxoTableAndImage {
        padding: 0 1rem 0 1rem;
    }
}