.header {
    background-color: var(--azul-1);
    font-family: Epilogue;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    padding: 1rem 3rem 1rem 2rem;
    position: relative;
    z-index: 2;
}

.headerContainer {
    background-image: url("../../../assets/image-plin-header.png");
    background-repeat: no-repeat;
    background-size: 40rem;
    background-position: right;
}

.headerRight {
    display: flex;
    align-items: center;
    gap: 2rem;
    position: relative;
    z-index: 2;
}

.headerRight p {
    color: #FFF;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    margin-top: 1rem;
    line-height: normal;
}

.headerRight img {
    width: 8rem;
    height: 6rem;
    flex-shrink: 0;
}

.headerText {
    color: #FFF;
    font-family: Epilogue;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.headerSecondary {
    background-color: #1B63F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 1rem 2rem;
    position: relative;
}

.headerSecondaryText {
    color: #fff;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
}

.userIcon {
    width: 2.2rem;
}

.companyName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}

.averageHeader {
    display: flex;
    gap: 3rem;
    justify-content: flex-end;
    align-items: center;
    padding-right: 3rem;
    color: #fff;
}

.averageLeft,
.averageRight {
    display: flex;
    align-items: center;
    color: #D8DCFF;
    line-height: 120.8%;
    font-weight: 700;
    gap: 0.3rem;
}


@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .headerSecondary {
        width: 120%;
    }

    .averageLeft {
        font-size: 1rem;
    }

    .averageRight {
        font-size: 1rem;
    }

    .averageHeader {
        padding-right: 18rem;
        gap: 3rem
    }
}

@media screen and (min-width: 768px) and (max-width: 987px) {
    .averageHeader {
        gap: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .headerContainer {
        flex-flow: column-reverse;
    }

    .header {
        align-items: flex-start;
    }

    .headerText {
        font-size: 1.8rem;
    }
    .companyName {
        margin-bottom: 1rem;
    }
    .headerSecondary {
        flex-wrap: wrap;
        flex-shrink: 1;
    }

    .averageHeader {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1rem;
    }
}