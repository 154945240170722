.section {
    padding: 3rem 3rem;
}

.sectionHeader {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
}

.boxInfosContainerMobile {
    display: none;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.lampIcon {
    width: 3rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.boxInfos {
    display: flex;
    height: 13rem;
    margin-right: 50px;
}

.boxInfosContainer {
    display: flex;
    gap: 4rem;
}

.boxInfosTitulos {
    margin-right: 1rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}

.boxInfosValores {
    height: 13rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}

.boxInfosTitulos h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    white-space: nowrap;
    font-weight: 700;
    line-height: normal;
}

.boxInfosValores h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    white-space: nowrap;
    font-weight: 400;
    line-height: normal;
}

.resumoProposta {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    gap: 1rem;
    padding-left: 5rem;
}


@media screen and (min-width: 768px) and (max-width: 987px) {
    .section {
        margin-top: 1rem;
    }

    .boxInfosContainer {
        display: flex;
        gap: 5rem;
    }
    .boxInfosValores h2{
        font-size: 1rem;
    }

    .boxInfosTitulos h2 {
        font-size: 1rem;
    }

    .boxInfos {
        margin: 0;
    }

    .section {
        padding: 0rem 3rem;
    }
}

@media screen and (max-width: 767px) {
    .boxInfosContainer {
        display: none;
    }

    .boxInfosContainerMobile {
        display: block;
    }

    .section {
        padding: 1rem 1rem;
        margin-top: 9rem;
        padding-bottom: 8rem;
    }

    .lampIcon {
        width: 2rem;
    }

    .title {
        font-size: 1.6rem;
    }

    .titleSection {
        gap: 1rem;
    }

    .resumoProposta {
        padding: 0;
    }
}

@media print {

    .section {
        margin-top: 0rem;
    }

    .lampIcon {
        width: 3rem;
        margin-bottom: 16px;
    }

    .title {
        font-size: 1.8rem;
    }

    .boxInfosTitulos h2 {
        font-size: 1.4rem;
        white-space: nowrap;
    }

    .boxInfosValores h2 {
        font-size: 1.4rem;
        white-space: nowrap;
    }

}