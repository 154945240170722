.bgSection1 {
    background-color: #FFF;
}

.sectionDesktop {
    padding: 3rem 3rem;
}

.sectionMobile {
    display: none;
    padding: 1rem 1rem;
}

.section1Header {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.flashLightIcon {
    width: 3rem;
    margin-bottom: 16px;
}

.cardContainer {
    display: flex;
    margin-left: 1rem;
}

.textBelowContainer {
    display: flex;
    gap: 13rem;
    margin-left: 7rem;
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.textRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1rem; 
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: .9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.powerNumbersGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}

.divisionLine {
    display: none !important;
    height: .15rem;
    background-color: #E7EFFE;
}

@media screen and (max-width: 767px) {
    .sectionDesktop {
        display: none;
    }
    .sectionMobile {
        display: block;
    }
}

@media screen and (min-width: 768px) and (max-width: 987px) {
    .textBelowContainer {
        margin-left: 3rem;
        gap: 3rem;
    }

    .sectionDesktop{
        padding: 0rem 3rem;
    }
}
@media (max-width: 1000px) {
    .powerNumbersGrid {
        grid-template-columns: repeat(2, 1fr);
    }

    .flashLightIcon {
        width: 2.5rem;
    }

    .title {
        font-size: 1.6rem;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;

    }

    .sectionDesktop {
        padding: 2rem 2rem;
    }

    .powerNumbersGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5px;
    }

    .sideMenu {
        display: none;
    }

    .title {
        font-size: 1.8rem;
    }

    .flashLightIcon {
        width: 3rem;
        margin-bottom: 16px;
    }

    .titleSection {
        gap: 2rem;
    }

    .textBelowContainer {
        gap: 5rem;
        font-size: 1.3rem;
        margin-left: 6rem;
    }

    .textRight{
        font-size: 1rem;
    }

    .divisionLine {
        display: block !important;
        height: .15rem;
        background-color: #E7EFFE;
    }

}