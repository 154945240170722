.realCheckbox {
    opacity: 1;
}

.checkboxDiv {
    display: flex;
    align-items: flex-start;
    place-items: center;
}

.checkboxDiv>label {
    font-size: .8rem;
    font-weight: 400;
    line-height: 120%;
    color: #575757;
    margin-left: .7vw;
}

.checkbox {
    box-sizing: border-box;
    margin-top: 4px !important;
}

.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox input[type="checkbox"]+label {
    width: 1vw;
    height: 1vw;
    border: 2px solid #CFCFCF;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    padding: 2px 10px 2px 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.checkbox input[type="checkbox"]:checked+label {
    background-color: var(--verde-positivo);
    border: 2px solid var(--verde-positivo);
}

.checkbox input[type="checkbox"]:checked+label::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
    transform: rotate(45deg);
}
