.buttonContainer {
    display: none;
    gap: 4rem;
    background-color: #bbb;
    margin-bottom: 5rem;
    padding: 1rem 0rem 1rem 0rem;
    background: #FFF;
    box-shadow: 0px -4px 35px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

@media screen and (max-width: 767px) {
    .buttonContainer {
        display: flex;
        position: fixed;
        justify-content: center;
        bottom: 0rem;
        margin-bottom: auto;
        gap: 1rem;
    }
}

@media print {
    .buttonContainer {
        display: none;
    }
}