.button {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.75rem;
    gap: 0.7rem;
    background-color: var(--azul-1);
    border: none;
    border-radius: 0px 0px 0px 0.8rem;
    cursor: pointer;
    width: 15rem;
    font-weight: 700;
    font-size: 0.787rem;
    line-height: 18px;
    color: white;
    transition: all ease-in 0.4s;
}

.button:hover {
    background-color: var(--amarelo);
    color: var(--azul-1);
}

.button.disable {
    background-color: #cfcfcf;
    cursor: not-allowed;
}

.button.disable:hover {
    background-color: #cfcfcf;
    cursor: not-allowed;
    color: #fff;
}

.button.disable:hover .icon {
    filter: brightness(80%) saturate(0%) invert(41%) sepia(0%) saturate(0%) hue-rotate(0deg);
}

.button:hover>* {
    filter: brightness(0) saturate(100%) invert(22%) sepia(89%) saturate(5386%) hue-rotate(242deg);
}

@media screen and (max-width: 767px) {
    .button {
        padding: 0.5rem 0.75rem;
        font-size: .6rem;
    }
}

@media screen and (min-width: 767px) and (max-width: 987px) {
    .button {
        width: 13rem;
        text-wrap: nowrap;
    }
}