.section {
    padding: 3rem 3rem;
}

.sectionHeader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.moneyIcon {
    width: 3rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}


.boxResultadosFinanceiros {
    display: flex;
    height: 13rem;
    gap: 10rem;
}

.boxResultados h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.resumoProposta {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    gap: 1rem;
    padding-left: 5rem;
}


.divisionLine {
    display: none !important;
    height: .15rem;
    background-color: #E7EFFE;
}

@media screen and (min-width: 768px) and (max-width: 987px) {
    .boxResultadosFinanceiros {
        gap: 3rem;
    }

    .boxResultados h2 {
        font-size: .8rem;
    }
    
    .boxResultados h1 {
        font-size: .8rem;
    }

}

@media screen and (max-width: 767px) {
    .boxResultadosFinanceiros {
        display: flex;
        height: 13rem;
        flex-direction: column;
        gap: 0;
    }

    .boxResultados h2 {
        font-size: 1.1rem;
    }

    .boxResultados h1 {
        font-size: 1.1rem !important;
    }
    
    .section {
        padding: 1rem 1rem;
    }

    .titleSection {
        gap: 1rem;
    }

    .title {
        font-size: 1.5rem;
    }

    .sectionHeader {
        gap: 0;
    }

    .resumoProposta {
        padding-left: 0;
    }

    .moneyIcon {
        width: 2.5rem;
    }
}

@media print {
    .boxResultadosFinanceiros {
        gap: 5rem;
    }
    .section {
        padding: 1rem 1rem;
    }

    .sectionHeader {
        gap: 1rem;
        margin-bottom: 0rem;
    }

    .title {
        font-size: 1.8rem;
    }

    .titleSection {
        gap: 1.5rem;
    }

    .resumoProposta {
        padding-left: 7rem;
        gap: 0;
    }
    

    .moneyIcon {
        width: 3.5rem;
        margin-bottom: 16px;
        margin-left: 1.8rem;
    }

    .divisionLine {
        display: block !important;
        height: .15rem;
        background-color: #E7EFFE;
    }
}