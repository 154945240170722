.sectionDesktop {
    padding: 3rem 3rem;
}

.sectionHeader {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.simboloPlin {
    width: 3rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.boxInfosProposta {
    margin-right: 1rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.6rem;
}

.boxInfosProposta h2 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.boxInfosProposta h1 {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: .6rem;
}

.resumoPropostaDesktop {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: .5rem;
    gap: 1rem;
    padding-left: 5rem;
}

.divisionLine {
    display: none !important;
    height: .15rem;
    background-color: #E7EFFE;
}

@media screen and (max-width: 767px) {
    .sectionDesktop {
        padding: 1rem 1rem;
    }

    .resumoPropostaDesktop {
        display: none;
    }

    .title {
        font-size: 1.6rem;
    }

    .simboloPlin {
        width: 2.5rem;
    }

}

