.inputPhone {
    box-sizing: border-box;
    border: 2px #CFCFCF solid;
    display: flex;
    align-content: flex-start;
    padding: 2vh;
    border-radius: 8px;
    transition: 300ms;
    width: 100%;
}

.inputPhone input {
    border: none;
    width: 100%;
    outline: none;
    margin-left: 10px;
    font-size: .9rem;
    line-height: 16, 4px;
    color: #575757;
}

.inputPhone img {
    width: 2.5vh;
    height: 2.5vh;
    transition: 300ms;
}

.isValidWhatsapp {
    border-color: var(--verde-positivo) !important;
    transition: 300ms;
}

.isValidWhatsapp input {
    color: var(--verde-positivo);
}
