.buttonContainer {
    display: flex;
    gap: 4rem;
    padding-left: 6rem;
    margin-bottom: 5rem;
}

@media screen and (max-width: 767px) {
    .buttonContainer {
        display: none;
        margin-top: 1rem;
        padding: 0rem 1rem 0rem 1rem;
        gap: 1rem;
    }
}

@media screen and (min-width: 767px) and (max-width: 987px) {
    .buttonContainer {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: 0;
        margin-bottom: 5rem;
    }
}

@media print {
    .buttonContainer {
        display: none;
    }
}