.imgAndTitle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    white-space: nowrap;
}

.imgAndTitle img {
    width: .8rem;
}

.imgAndTitle h1 {
    color: #1B29F0;
    font-family: Epilogue;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media screen and (min-width: 768px) and (max-width: 987px) {
    .imgAndTitle h1 {
        font-size: 1rem;
    }

    .imgAndTitle h2 {
        font-size: 1rem;
    }
}

@media print {

    .imgAndTitle img {
        width: .5rem;
    }
    
    .imgAndTitle h2 {
        font-size: 1.3rem;
    }

    .imgAndTitle h1 {
        font-size: 1.3rem;
    }
    
}