.sideMenu {
    position: fixed;
    top: 50%;
    z-index: 2;
    right: 2rem;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 767px) and (max-width: 987px) {
    .sideMenu {
        top: unset;
        bottom: 1rem;
        transform: none;
    }
}

@media screen and (max-width: 767px) {
    .sideMenu {
        display: none;
    }
}

@media print {
    .sideMenu {
        display: none;
    }
}