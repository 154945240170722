.button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.3rem 1.75rem;
	gap: 0.7rem;

	background-color: #e03f5c;
	border: none;
	border-radius: 0px 0px 0px 0.5rem;
	border: 3px solid #e03f5c;
	cursor: pointer;

	font-weight: 700;
	font-size: 0.787rem;
	line-height: 18px;
	color: white;

	transition: all ease-in 0.4s;
}

.button:hover {
	background-color: #fff;
	color: #e03f5c;
}

.button.disable:hover {
	background-color: var(--cinza-disabled);
	color: var(--cinza);
	cursor: not-allowed;
	border: 3px solid var(--cinza-disabled);
}

.button.disable:hover .icon {
	filter: brightness(80%) saturate(0%) invert(41%) sepia(0%) saturate(0%)
		hue-rotate(0deg);
}
