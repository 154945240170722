.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    height: 7vh;
    background-color: var(--azul-1);
    border: none;
    border-radius: 0px 0px 0px 20px;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    line-height: 18px;
    color: white;
    
    transition: 300ms;
}

.arrow {
    width: 16px;
    height: 16px;
    background-color: var(--amarelo);
    mask: url(../../assets/arrow.svg) no-repeat center;
    transition: 300ms;
}

button.disable {
    background-color: #CFCFCF !important;
    cursor: not-allowed !important;
    color: #575757 !important;
}

button.disable .arrow {
    background-color: #575757;
}
