.button {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.75rem;
    gap: 0.7rem;
    border: none;
    cursor: pointer;
    width: 40%;
    font-weight: 700;
    font-size: 0.787rem;
    line-height: 18px;
    color: var(--azul-1);
    transition: all ease-in 0.4s;
    border-radius: 0rem 0rem 0rem 0.625rem;
    background: linear-gradient(136deg, #FFF 0%, #F3F4FF 100%);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    text-decoration: none;
}

.button:hover {
    background-color: var(--amarelo);
    color: var(--azul-1);
}

.button.disable {
    background-color: #cfcfcf;
    cursor: not-allowed;
}

.button.disable:hover {
    background-color: #cfcfcf;
    cursor: not-allowed;
    color: #fff;
}

.button.disable:hover .icon {
    filter: brightness(80%) saturate(0%) invert(41%) sepia(0%) saturate(0%) hue-rotate(0deg);
}

.button:hover>* {
    filter: brightness(0) saturate(100%) invert(22%) sepia(89%) saturate(5386%) hue-rotate(242deg);
}

@media screen and (max-width: 767px) {
    .button {
        padding: 0.5rem 0.75rem;
        font-size: .8rem;
    }
}