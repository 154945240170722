.iconTd {
	padding: 0 0.2rem !important;
}

.iconTd img {
	width: 1.2rem;
	cursor: pointer;
	transition: all 0.3s;
}

.iconTd img:hover {
	transform: scale(1.1) translateX(5%);
}


.evenRow {
	background-color: #FFF;
}

.evenRow td {
	color: #5E5E5E;
	text-align: center;
	font-family: Epilogue;
	font-size: 1rem !important;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}


.oddRow {
	background: rgba(245, 248, 254, 0.84);
}

.oddRow td {
	color: #5E5E5E;
	text-align: center;
	font-family: Epilogue;
	font-size: 1rem !important;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
}

.rowAno {
	color: #000 !important;
	font-family: Epilogue;
	font-size: 1rem !important;
	font-style: normal;
	font-weight: 700 !important;
	line-height: normal;
}

.tableFluxo {
    overflow-x: auto;
    transition: transform 0.5s ease-out;
    animation-fill-mode: both; 
}

.shake-animation {
    animation: shake 2s forwards;
	animation-iteration-count: 2;
}


@media screen and (max-width: 987px) {
	.tableFluxo {
		overflow-x: auto;
		transition: transform 0.5s ease-out;
		animation-fill-mode: both; 
	}
	
	.shake-animation {
		animation: shake 2s forwards;
		animation-iteration-count: 2;
	}

	@keyframes shake {
		0% {
			transform: translateX(0); 
		}
	
		25%, 75% {
			transform: translateX(-10px);
		}
	
		50% {
			transform: translateX(10px);
		}
	
		100% {
			transform: translateX(0); 
		}
	}
	
}

@media print {
	.tableFluxo {
		overflow-x: hidden;
	}

	.evenRow td {
		font-size: 1rem !important;
	}

	.oddRow TD {
		font-size: 1rem !important;
	}
}