.bgSection1 {
    background-color: #FFF;
}

.section1 {
    padding: 3rem 3rem;
}

.section1Header {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.coinIcon {
    width: 4rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.roundedTextBox {
    display: flex;
    width: 36.75rem;
    align-items: flex-start;
    align-content: flex-start;
    gap: 0.625rem;
    margin-top: 0.2rem;
    flex-wrap: wrap;
}

.sideMenu {
    position: fixed;
    top: 50%;
    z-index: 2;
    right: 2rem;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.powerNumbersGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
}

@media print {
    .section1 {
        margin-bottom: 1rem;
    }

    .powerNumbersGrid {
        display: grid;
        grid-template-columns: repeat(3, 0fr);
        margin-left: -4rem;
    }

    .sideMenu {
        display: none;
    }

    .title {
        font-size: 1.8rem;
    }

    .coinIcon {
        width: 3rem;
        margin-bottom: 16px;
    }

    .titleSection {
        gap: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .sideMenu {
        display: none;
    }
    .section1 {
        padding: 1rem 1rem;
    }

    .coinIcon {
        width: 3rem;
    }

    .title {
        font-size: 1.5rem;
    }
    
    .roundedTextBox {
        width: auto;
        margin-left: -1rem;
    }

    .titleSection{
        align-items: flex-start;
        gap: .6rem;
    }

    .powerNumbersGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        grid-column-gap: 3rem;
        margin-left: 2rem;
    }
    
}
