.bgSection {
    background: linear-gradient(180deg, #F2F7FF 41.15%, rgba(255, 255, 255, 0.00) 100%);
}

.section {
    padding: 3rem 3rem;
}

.sectionHeader {
    display: flex;
    align-items: center;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}

.contentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.titleSection {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.plinIcon {
    width: 4rem;
    margin-bottom: 16px;
}

.title {
    display: flex;
    align-items: center;
    color: #242424;
    font-family: Epilogue;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120.8%;
}

.gestaoText {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    gap: 1rem;
    padding-left: 6rem;
}

.gestaoText p {
    color: #5F5F5F;
    font-family: Epilogue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.photosContainer {
    position: relative;
    width: 100%;
    max-width: 62rem;
    margin: 0 auto;
}

.desktopImg {
    position: absolute;
    width: 70%;
    top: -14rem;
    right: -6%;
}

@media screen and (max-width: 767px) {
    .section {
        padding: 1rem 1rem;
        margin-bottom: -3rem;
    }

    .title {
        font-size: 1.6rem;
    }

    .flexContainer {
        display: flex;
        align-items: center;
    }

    .titleSection {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .plinIcon {
        width: 2rem;
    }

    .desktopImg {
        width: 14rem;
        top: -10rem;
        right: -6rem;
    }

    .boxGestaoPlin {
        font-size: 1.4rem;
        width: 16rem;
    }

    .photosContainer {
        position: relative;
    }

    .gestaoText {
        padding-left: 0rem;
        margin-left: .5rem;
        gap: .4rem;
    }

    .gestaoText p {
        font-size: 1.2rem;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .desktopImg {
        width: 18rem;
    }

    .gestaoText{
        white-space: nowrap;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .section {
        margin-top: 1rem;
    }

    .gestaoText {
        padding-left: 3.5rem;
        gap: 0.5rem;

    }

    .sideMenu {
        display: none;
    }

    .gestaoText p {
        font-size: 1.3rem;
    }

    .title {
        font-size: 1.8rem;
    }

    .titleSection {
        gap: 1.2rem;
        margin-left: -0.8rem;

    }

    .plinIcon {
        width: 3rem;
        margin-bottom: 16px;
    }

    .desktopImg {
        width: 85%;
        top: -14rem;
        right: -10%;
    }

}